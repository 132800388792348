.gallery {
	display: flex;
	flex-wrap: wrap;
}

.gallery > div {
	margin: 1rem;
	cursor: pointer;
	position: relative;
}

.gallery img,
.gallery video {
	max-width: 200px;
}

.modal {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
	background-color: rgba(0, 0, 0, 0.8);
	z-index: 999;
}

.modal img,
.modal video {
	max-height: 90%;
	max-width: 90%;
}

/* Gallery container styles */
.chakra-modal__content-container {
	backdrop-filter: blur(10px);
}

/* Smooth loading animation for images */
/* img {
	opacity: 0;
	animation: fadeIn 0.3s ease-in forwards;
} */

@keyframes fadeIn {
	from {
		opacity: 0;
	}
	to {
		opacity: 1;
	}
}

/* Video hover effect */
video:hover {
	filter: brightness(1.1);
}

/* Modal animations */
.chakra-modal__content {
	transform: scale(0.95);
	opacity: 0;
	animation: modalEnter 0.2s ease-out forwards;
}

@keyframes modalEnter {
	to {
		transform: scale(1);
		opacity: 1;
	}
}

/* Smooth transitions for interactive elements */
.chakra-button,
.chakra-icon-button {
	transition: all 0.2s ease-in-out !important;
}

/* Improve scrollbar aesthetics */
::-webkit-scrollbar {
	width: 8px;
	height: 8px;
}

::-webkit-scrollbar-track {
	background: transparent;
}

::-webkit-scrollbar-thumb {
	background: rgba(0, 0, 0, 0.2);
	border-radius: 4px;
}

::-webkit-scrollbar-thumb:hover {
	background: rgba(0, 0, 0, 0.3);
}
